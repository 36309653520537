import { Route } from "react-router-dom";
import { TimeZoneList } from "../consts/tz-list";
import { HomePage } from "./homepage";
import { getLocaleKeys } from "../i18n/locales";
import { TZAbbrev } from "../timezone-table/interface";

export const HomePageRoutes = (): JSX.Element[] => {
    const tzList: TZAbbrev[] = TimeZoneList.map(tz => tz.abbrev);
    const homePageRoutes: JSX.Element[] = [];
    const times = [
        "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am","12am",
        "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm","12pm",
    ];
    tzList.forEach(tzFrom => {
        tzList.forEach(tzTo => {
            if (tzFrom !== tzTo) {
                getLocaleKeys().forEach(localeKey => {
                        const path = `${localeKey}/timezone-converter/${tzFrom}-to-${tzTo}`.toLowerCase();
                        homePageRoutes.push(<Route path={path} key={path} element={<HomePage language={localeKey} fromTimeZone={tzFrom} toTimeZone={tzTo} />} />);
                });
                const defaultPath = `timezone-converter/${tzFrom}-to-${tzTo}`.toLowerCase();
                homePageRoutes.push(<Route path={defaultPath} key={defaultPath} element={<HomePage language={'en'} fromTimeZone={tzFrom} toTimeZone={tzTo} />} />);

                const tzFromL = tzFrom.toLowerCase();
                const tzToL = tzTo.toLowerCase();

                const condition1 = tzFromL === 'ist' && tzToL === 'cst';
                const condition2 = tzFromL === 'cst' && tzToL === 'ist';
                const condition3 = tzFromL === 'ist' && tzToL === 'est';
                const condition4 = tzFromL === 'est' && tzToL === 'ist';
                const condition5 = tzFromL === 'ist' && tzToL === 'pst';
                const condition6 = tzFromL === 'pst' && tzToL === 'ist';
                const condition7 = tzFromL === 'ist' && tzToL === 'gmt';
                const condition8 = tzFromL === 'gmt' && tzToL === 'ist';

                if (condition1 || condition2 || condition3 || condition4 || condition5 || condition6 || condition7 || condition8) {
                    times.map(time => {
                        const defaultPath = `timezone-converter/${time}-${tzFrom}-to-${tzTo}`.toLowerCase();
                        homePageRoutes.push(<Route path={defaultPath} key={defaultPath} element={<HomePage language={'en'} fromTimeZone={tzFrom} toTimeZone={tzTo} fromTime={time} />} />);

                        getLocaleKeys().forEach(localeKey => {
                            const path = `${localeKey}/timezone-converter/${time}-${tzFrom}-to-${tzTo}`.toLowerCase();
                            homePageRoutes.push(<Route path={path} key={path} element={<HomePage language={localeKey} fromTimeZone={tzFrom} toTimeZone={tzTo} fromTime={time} />} />);
                        });
                    });
                }
            }
        });
    });

    getLocaleKeys().forEach(key => {
        homePageRoutes.push(<Route path={`${key}`} key={`${key}`} element={<HomePage language={key} />} ></Route>);
    });

    homePageRoutes.push(<Route path="/" key="root" element={<HomePage language='en' />} ></Route>);

    return homePageRoutes;
}